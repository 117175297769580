import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { CreditDecisionDetails, getCreditDecisionInfo, resendPin, validatePin } from '../../api/creditDecisionApi';
import { useCreditAppState } from '../../contexts/CreditAppContext';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { CreditAppState } from '../../CreditApp.model';
import { useInterval } from '../../customHooks/useInterval.hook';
import { ADOBE_TAGS } from '../../types/AdobeTags';
import { CREDIT_DECISION_TIMERS, DEALER_DECISION_TYPES } from '../../types/Constants';
import { Resources } from '../../types/ExternalResources';
import { KeyValuePair } from '../../types/KeyValuePair';
import { isValidDealerDecision } from '../../utils/customValidation/CustomValidation';
import {
    sendAdobeEvents,
    sendCreditDecisionsAvailable,
    sendCreditDecisionsPending,
    sendDecisionHeightUpdate,
    sendDecisionPinValidated
} from '../../utils/eventUtils';
import { emptyString, isLocal } from '../../utils/helper';
import { getUrlVars } from '../../utils/urlParameters';
import DealerDecisionPage from './DealerDecisionPage';
import LenderDecisionPage from './LenderDecisionPage';

export enum CreditDecisionState {
    Approved = 'Approved',
    ConditionallyApproved = 'ConditionallyApproved',
    Declined = 'Declined',
    Pending = 'Pending',
    Loading = 'Loading'
}

function getInitialDecisionStateFromParams(urlParameter: KeyValuePair): CreditDecisionState {
    const pendingStateInParams = urlParameter?.pending;
    let initialState = CreditDecisionState.Loading;

    // For local testing and automation tests, forces decision returned and bypass axios request
    if (emptyString(pendingStateInParams)) {
        initialState = CreditDecisionState.Loading;
    } else if (pendingStateInParams === 'true') {
        initialState = CreditDecisionState.Pending;
    } else if (pendingStateInParams === 'false') {
        initialState = CreditDecisionState.Approved;
    }

    return initialState;
}

const CreditDecisionLandingPage: React.FC<{ location?: Location }> = ({ location }) => {
    const state = useCreditAppState();
    const externalResourcesState = useExternalResourceState();
    const theme = useTheme();
    const urlParameter = useMemo(() => getUrlVars(), []);

    const { toggles } = (externalResourcesState as Resources)?.toggles ?? {};
    const enableAppliedLenderInfo = toggles?.enableAppliedLenderInfo;
    const enableCreditDecisionDetails = toggles?.enableCreditDecisionDetails;

    const isEmbedded = !emptyString(urlParameter?.sessionId);
    const isDealerIdInUrl = !emptyString(urlParameter?.dealerId);
    // the accelerateCreditAppId comes from dr-activities-credit-app/IOffer, it becomes the shadowCreditAppId for querying Dynamo
    const isShadowCreditAppIdInUrl = !emptyString(urlParameter.accelerateCreditAppId);
    const isPreSelectedDecisionInUrl = !emptyString(urlParameter.selectedDecision);
    const [shadowCreditAppId, setShadowCreditAppId] = useState(urlParameter?.accelerateCreditAppId);
    const dealerId = isDealerIdInUrl ? urlParameter?.dealerId : '';
    const enableAccelerateLoader = urlParameter?.enableAccelerateLoader === 'true';
    const spAssetsVersion = urlParameter?.spAssetsVersion;
    const sessionId = urlParameter?.sessionId;
    const shouldBypassTimerOnRender = enableCreditDecisionDetails && urlParameter?.isCreditDecisionAvailable === 'true';
    const AccelerateGifSrc = isEmbedded
        ? `${(externalResourcesState as Resources)['sp-static-assets']}img/reservations-loading.gif`
        : '';
    const shopperEmail = (externalResourcesState as Resources).offer?.shopper.email ?? '';
    const dealXgId = (externalResourcesState as Resources).dealXgId ?? '';

    // Card Hooks:
    const [targetDecision, setTargetDecision] = useState({});
    const currentDecisionRef = useRef(targetDecision);
    const [decisionHasBeenSelected, setDecisionHasBeenSelected] = useState(false);
    const [decisionStatus, setDecisionStatus] = useState(() => getInitialDecisionStateFromParams(urlParameter));
    const [decisionDetails, setDecisionDetails] = useState<CreditDecisionDetails | undefined>(undefined);

    // Timer Hooks:
    const [userTimer, setUserTimer] = useState(1);
    const [userIsInactive, setUserIsInactive] = useState(false);
    const [decisionsWereFetchedSuccessfully, setDecisionsWereFetchedSuccessfully] = useState(false);
    const [bypassDecisionTimer, setBypassDecisionTimer] = useState(shouldBypassTimerOnRender || false);

    // PinAuth hooks
    const [userEmail, setUserEmail] = useState('');
    const [userPinCode, setUserPinCode] = useState('');
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showPinModal, setShowPinModal] = useState(false);
    const [pinIsValid, setPinIsValid] = useState(false);
    const [showPinErrorMessage, setShowPinErrorMessage] = useState(false);

    // Workflow case logic: the `business logic` for manipulating the application flow from state variables
    const userSelectedActiveDecision = decisionHasBeenSelected && currentDecisionRef.current !== '';
    const userSuppliedValidEmail = userEmail !== '';
    const userSuppliedValidPin = userPinCode !== '' && pinIsValid;
    const [dealerPhone, setDealerPhone] = useState((externalResourcesState as Resources)?.dealer?.phone ?? '');

    // Flags used for applied lender info new logic
    const { dealer } = externalResourcesState as Resources;
    const isAccelerateCreditDecisionEnabled = dealer?.isAccelerateCreditDecisionEnabled;

    const confirmationPageToggles = {
        isAccelerateCreditDecisionEnabled,
        enableAppliedLenderInfo,
        enableCreditDecisionDetails
    };

    const creditAppOffer = (externalResourcesState as Resources).offer;
    const offerType = creditAppOffer?.type;
    const dealerDecision = (externalResourcesState as Resources).dealerDecision;
    const isDealerDecisionFlow = enableAppliedLenderInfo && isValidDealerDecision(dealerDecision);

    const getDefaultUserEmail = useCallback(() => {
        const { personalInfo } = state as CreditAppState;
        const { offer } = externalResourcesState as Resources;
        let email = '';
        // check for immediate hook state (from pinAuth/email)
        if (userEmail && userEmail !== '') {
            email = userEmail;
        }
        // check for creditAppState email (from credit app submission)
        else if (personalInfo?.primaryApplicant?.email?.value !== '') {
            email = personalInfo?.primaryApplicant?.email?.getTrimmed();
        }
        // check for email in externalResources context (PENDING)
        else if (offer?.shopper?.email !== '') {
            email = offer?.shopper?.email || '';
        }

        setUserEmail(email);
    }, [externalResourcesState, state, userEmail]);

    const resetUserInactiveTime = useCallback(() => {
        if (isLocal) console.log('User clicked, so resetting the userTimer', userTimer);
        setUserTimer(0);
    }, [userTimer]);

    const validatePinCode = useCallback(
        async (pin: string) => {
            return validatePin(userEmail, pin)
                .then((resp) => resp.status === 200)
                .catch(() => false);
        },
        [userEmail]
    );

    const restartUserTimer = useCallback(() => {
        setUserPinCode('');
        setUserIsInactive(false);
        setUserTimer(0);
    }, []);

    const saveEmail = useCallback(
        async (email: string) => {
            resendPin(shadowCreditAppId, email, dealXgId).then(() => {
                setShowEmailModal(false);
                setShowPinModal(true);
            });
        },
        [dealXgId, shadowCreditAppId]
    );

    const savePin = useCallback(
        async (e: any) => {
            const pin = e.currentTarget.parentElement.querySelector('input').value;
            setUserPinCode('');
            if (await validatePinCode(pin)) {
                setPinIsValid(true);
                sendDecisionPinValidated();
                setShowPinModal(false);
                restartUserTimer();
                setShowPinErrorMessage(false);
            } else {
                setPinIsValid(false);
                setShowPinModal(true);
                setShowPinErrorMessage(true);
            }
        },
        [restartUserTimer, validatePinCode]
    );

    const handleCloseEmailModal = useCallback((e: any) => {
        setShowEmailModal(false);
        setShowPinModal(true);
    }, []);

    const handleClosePinModal = useCallback((e: any) => {
        setShowPinModal(false);
        setShowEmailModal(true);
    }, []);

    const handleSkipLoadingAnimation = useCallback((e: any) => {
        setBypassDecisionTimer(true);
        sendAdobeEvents(ADOBE_TAGS.DR_CREDIT_APP_DECISION_WAIT_PAGE_CONTINUE_TO_NEXT_STEPS_CLICKED);
        sendCreditDecisionsPending();
    }, []);

    // User Activity timers
    useEffect(() => {
        // TODO: Make this into a flag
        const forceInactiveUser = Boolean(urlParameter?.inactiveUserTimer);
        const inactiveUserTimer = !emptyString(urlParameter?.inactiveUserTimer)
            ? urlParameter?.inactiveUserTimer
            : CREDIT_DECISION_TIMERS.INACTIVE_USER_LIMIT;
        let interval: any;
        if (!userSelectedActiveDecision) {
            interval = setInterval(() => {
                if (forceInactiveUser || (decisionsWereFetchedSuccessfully && userIsInactive === false)) {
                    const userTimeNow = userTimer + 1;
                    setUserTimer(userTimeNow);
                    if (userTimer >= (inactiveUserTimer as number)) {
                        setUserIsInactive(true);
                    }
                }
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setUserIsInactive(true);
            return () => clearInterval(interval);
        }
    }, [decisionsWereFetchedSuccessfully, urlParameter, userIsInactive, userSelectedActiveDecision, userTimer]);

    // Click handler for resetting user inactive time
    useEffect(() => {
        if (!userIsInactive) {
            window.addEventListener('click', resetUserInactiveTime);
        }
        return () => {
            window.removeEventListener('click', resetUserInactiveTime);
        };
    }, [resetUserInactiveTime, userIsInactive]);

    // Should not trigger creditDecision request if we are inside dealerDecision flow
    const [decisionDelay, setDecisionDelay] = useState<number | null>(
        isDealerDecisionFlow ? null : CREDIT_DECISION_TIMERS.DECISION_REQUEST_INTERVAL
    );

    const requestCreditDecisionStatus = useCallback(() => {
        return getCreditDecisionInfo({
            isEmbedded,
            shadowCreditAppId,
            dealerId,
            spAssetsVersion,
            sessionId
        });
    }, [isEmbedded, shadowCreditAppId, dealerId, spAssetsVersion, sessionId]);

    // frequent calls to get the last credit decision status
    useInterval(() => {
        // TODO: debounce calls to avoid multiple pending requests
        // when the connection is slow or the server's response time is longer than 5 seconds.
        requestCreditDecisionStatus().then(({ status, dealer, creditDecisionDetails, finalDecision }) => {
            if (status === CreditDecisionState.Approved) {
                setDecisionDelay(null);
                setDealerPhone(dealer.phone);
                setDecisionStatus(CreditDecisionState[status]);
                setDecisionsWereFetchedSuccessfully(true);
                sendCreditDecisionsAvailable(creditDecisionDetails);
                if (creditDecisionDetails) {
                    setDecisionDetails(creditDecisionDetails);
                }
            }
            if (enableCreditDecisionDetails && finalDecision && status === CreditDecisionState.Pending) {
                setDecisionDelay(null);
                setDealerPhone(dealer.phone);
                setDecisionStatus(CreditDecisionState[status]);
                setDecisionsWereFetchedSuccessfully(true);
                sendCreditDecisionsPending();
            }
        });
    }, decisionDelay);

    // async handler for fetching decisions
    useEffect(() => {
        function creditDecisionLastRequest() {
            setDecisionDelay(null);
            requestCreditDecisionStatus().then(({ status, dealer, creditDecisionDetails }) => {
                setDealerPhone(dealer.phone);
                setDecisionStatus(CreditDecisionState[status]);
                setDecisionDetails(creditDecisionDetails);

                if (status === CreditDecisionState.Approved) {
                    sendCreditDecisionsAvailable(creditDecisionDetails);
                } else {
                    sendCreditDecisionsPending();
                }

                setDecisionsWereFetchedSuccessfully(true);
            });
        }
        // Should not trigger creditDecision request if we are inside dealerDecision flow
        if (!isDealerDecisionFlow) {
            if (bypassDecisionTimer) {
                creditDecisionLastRequest();
            } else if (decisionStatus === CreditDecisionState.Loading) {
                const timer = setTimeout(() => {
                    creditDecisionLastRequest();
                }, CREDIT_DECISION_TIMERS.DECISION_REQUEST_TIMER);

                return () => clearTimeout(timer);
            }
        }
    }, [bypassDecisionTimer, decisionStatus, isDealerDecisionFlow, requestCreditDecisionStatus]);

    // handler for updating the selectedDecision in the URL to be the target decision
    useEffect(() => {
        if (urlParameter.selectedDecision && isPreSelectedDecisionInUrl) {
            currentDecisionRef.current = urlParameter.selectedDecision;
            setTargetDecision(urlParameter.selectedDecision);
            setDecisionHasBeenSelected(true);
        }
    }, [isPreSelectedDecisionInUrl, urlParameter.selectedDecision]);

    const getContentHeight = (): number => {
        const root = document.getElementById('root');
        if (!root) return 0;
        const app = root.getElementsByClassName('App')[0];
        if (!app) return 0;
        return app.scrollHeight || 0;
    };

    // auto-height fix
    useEffect(() => {
        let mounted = true;
        if (mounted) {
            let currentHeight = getContentHeight();
            const checkHeight = () => {
                const contentHeight = getContentHeight();
                if (contentHeight !== currentHeight) {
                    currentHeight = contentHeight;
                    sendDecisionHeightUpdate(currentHeight);
                }
                setTimeout(checkHeight, 500);
            };
            checkHeight();
        }
        return function cleanup() {
            mounted = false;
        };
    }, []);

    // for hide/show of the pin code modal against the inactive status
    useEffect(() => {
        if (userIsInactive) {
            userSuppliedValidPin === false ? setShowPinModal(true) : setShowPinModal(false);
            setShowEmailModal(false);
        } else {
            setShowPinModal(false);
            setShowEmailModal(false);
        }
    }, [userIsInactive, userSuppliedValidEmail, userSuppliedValidPin]);

    // sets the userEmail from one of the known state variables (pinAuth, creditAppState, externalResourcesState)
    useEffect(() => {
        getDefaultUserEmail();
    }, [getDefaultUserEmail]);

    // sets the shadowCreditAppId to the `accelerateCreditAppId` queryParam being passed from credit activity iframe
    useEffect(() => {
        if (isShadowCreditAppIdInUrl && state.creditDecision.shadowCreditAppId === '')
            setShadowCreditAppId(urlParameter.accelerateCreditAppId);
    }, [isShadowCreditAppIdInUrl, urlParameter.accelerateCreditAppId, state.creditDecision.shadowCreditAppId]);

    // sets the shadowCreditAppId to value from creditAppState.creditDecision.shadowCreditAppId (if it exists)
    useEffect(() => {
        if (isShadowCreditAppIdInUrl === false && state.creditDecision.shadowCreditAppId !== '') {
            setShadowCreditAppId(state.creditDecision.shadowCreditAppId);
        }
    }, [isShadowCreditAppIdInUrl, state.creditDecision.shadowCreditAppId]);

    let data: any = {
        theme,
        AccelerateGifSrc,
        handleSkipLoadingAnimation,
        showSkipLink: shadowCreditAppId !== '',
        showPinModal,
        showPinErrorMessage,
        savePin,
        handleClosePinModal,
        saveEmail,
        showEmailModal,
        handleCloseEmailModal,
        dealerPhone,
        offerType,
        confirmationPageToggles,
        userIsInactive,
        decisionDetails,
        shopperEmail
    };

    if (enableAppliedLenderInfo && dealerDecision) {
        data = {
            ...data,
            status: dealerDecision.creditDecisionStatus ?? DEALER_DECISION_TYPES.PENDING,
            dealerDecision
        };
        return <DealerDecisionPage data={data} />;
    } else {
        data = {
            ...data,
            decisionStatus,
            enableAccelerateLoader
        };
        return <LenderDecisionPage data={data} />;
    }
};
export default CreditDecisionLandingPage;
