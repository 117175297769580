import { FormField, Validator } from '../../../formValidator';
import { Clonable } from '../../../utils/Immer.class';
import { states } from '../../../utils/selectOptions';
import {
    validateAddress,
    validateCity,
    validateCounty,
    validateMonth,
    validateState,
    validateSuiteApartmentNumber,
    validateYear,
    validateZipCode
} from '../../../utils/Validation';
import { Cloned } from '../../Interfaces';

export class Address extends Validator implements Clonable<Address> {
    streetAddress: FormField.Input;
    city: FormField.Input;
    county: FormField.Input;
    state: FormField.Select;
    zip: FormField.Input;
    suiteAptNumber: FormField.Input;
    yearsAtAddress: FormField.Input;
    monthsAtAddress: FormField.Input;
    constructor(public isPrevious = false, me?: Cloned<Address>) {
        super();
        this.isPrevious = isPrevious;
        this.streetAddress =
            me?.streetAddress ??
            new FormField.Input({
                name: 'streetAddress',
                placeholder: 'e.g. 1800 Main Street',
                label: `${isPrevious ? 'Previous ' : ''}Street Address`,
                optional: false,
                errorMessage:
                    // eslint-disable-next-line max-len
                    'Street Address must contain a street number and a street name that is no longer than 6 numbers and 20 characters.',
                validationFunction: validateAddress,
                maxlength: 60,
                tooltip: 'The maximum length for this field is 60 characters'
            });
        this.city =
            me?.city ??
            new FormField.Input({
                name: 'city',
                placeholder: 'e.g. Atlanta',
                label: 'City',
                optional: false,
                errorMessage: 'Must be a valid city name',
                validationFunction: validateCity,
                maxlength: 30,
                tooltip: 'The maximum length for this field is 30 characters'
            });
        this.county =
            me?.county ??
            new FormField.Input({
                name: 'county',
                placeholder: 'e.g. Nassau',
                label: 'County',
                optional: true, // Sean: Make sure we can set false for UCA experience
                errorMessage: 'Must be a valid county name',
                validationFunction: validateCounty,
                maxlength: 20,
                tooltip: 'The maximum length for this field is 20 characters'
            });
        this.state =
            me?.state ??
            new FormField.Select({
                name: 'state',
                placeholder: 'State',
                label: 'State',
                optional: false,
                selectOptions: states,
                errorMessage: 'Please select a state',
                validationFunction: validateState
            });
        this.zip =
            me?.zip ??
            new FormField.Input({
                name: 'zip',
                placeholder: 'e.g. 30339',
                label: 'Zip',
                optional: false,
                errorMessage: 'Enter a valid 5 digit zip code',
                validationFunction: validateZipCode,
                maxlength: 5
            });
        this.suiteAptNumber =
            me?.suiteAptNumber ??
            new FormField.Input({
                name: 'suiteAptNumber',
                placeholder: 'e.g. Apt 101',
                label: 'Suite/ Apartment Number',
                optional: true,
                errorMessage:
                    // eslint-disable-next-line max-len
                    'Suite/ Apartment Number must contain a suite/apartment number that is no longer than 5 numbers and 5 characters.',
                validationFunction: validateSuiteApartmentNumber,
                maxlength: 60,
                tooltip:
                    // eslint-disable-next-line max-len
                    'The maximum length for this field is 60 characters. Suite/Apartment number maximum length is 5.'
            });
        this.yearsAtAddress =
            me?.yearsAtAddress ??
            new FormField.Input({
                name: 'yearsAtAddress',
                placeholder: 'Years',
                label: '',
                customRequiredLabel: 'Required. Enter an amount from 0-99.',
                errorMessage: 'Enter an amount from 0 to 99.',
                maxlength: 2,
                optional: false,
                validationFunction: (value) => validateYear(value)
            });
        this.monthsAtAddress =
            me?.monthsAtAddress ??
            new FormField.Input({
                name: 'monthsAtAddress',
                placeholder: 'Months',
                label: '',
                customRequiredLabel: 'Required. Enter an amount from 0-11.',
                errorMessage: 'Enter an amount from 0 to 11.',
                maxlength: 2,
                optional: false,
                validationFunction: (value) => validateMonth(value)
            });
    }

    clone(): Address {
        const cloneAddress = new Address(this.isPrevious, { ...this });
        return cloneAddress;
    }

    static copy(model: Address): Address {
        const cloneAddress = new Address();
        cloneAddress.streetAddress.value = model.streetAddress.value;
        cloneAddress.city.value = model.city.value;
        cloneAddress.county.value = model.county.value;
        cloneAddress.state.value = model.state.value;
        cloneAddress.zip.value = model.zip.value;
        cloneAddress.zip.tooltip = model.zip.tooltip;
        cloneAddress.suiteAptNumber.value = model.suiteAptNumber.value;
        return cloneAddress;
    }
}
