import React from 'react';

import { CheckboxWrapper, Column, Row, TitleWrapper } from '../../components/shared.styled';
import { CheckboxFormField, InputFormField, SelectFormField } from '../../formFields';
import ZipCodeFormField from '../../formFields/ZipCodeFormField';
import { Spouse } from '../../pages/HousingInfo/models/Spouse.model';
import { SpouseDispatcher } from './Spouse.Dispatcher';

type SpouseInfoFormProps = {
    title: string;
    spouse: Spouse;
    spouseDispatcher: SpouseDispatcher;
    className?: string;
    zipStateErrorMessage?: string;
    isCoApplicant?: boolean;
    isUCAEmbeddedExperience?: boolean;
};

const SpouseInfoForm: React.FC<SpouseInfoFormProps> = ({
    title,
    spouse,
    spouseDispatcher,
    className,
    zipStateErrorMessage,
    isCoApplicant,
    isUCAEmbeddedExperience
}) => {
    const { firstName, middleName, lastName, address, spouseAddressSameAsPrimaryApplicant } = spouse;
    const { streetAddress, city, state, zip, county, suiteAptNumber } = address;
    county.optional = isUCAEmbeddedExperience ? false : true;

    return (
        <div className={className} data-testid={isCoApplicant ? 'co-applicant-spouse' : 'applicant-spouse'}>
            <TitleWrapper>{title}</TitleWrapper>
            {isUCAEmbeddedExperience ? (
                <span className="align-field-mobile">
                    <Row>
                        <Column>
                            <InputFormField formField={firstName} onChangeHandler={spouseDispatcher.updateSpouseInfo} />
                        </Column>
                        <Column>
                            <InputFormField formField={middleName} onChangeHandler={spouseDispatcher.updateSpouseInfo} />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <InputFormField formField={lastName} onChangeHandler={spouseDispatcher.updateSpouseInfo} />
                        </Column>
                        <Column></Column>
                    </Row>
                    <CheckboxWrapper>
                        <CheckboxFormField
                            formField={spouseAddressSameAsPrimaryApplicant}
                            onChangeHandler={spouseDispatcher.updateSpouseAddressSameAsPrimaryApplicant}
                        />
                    </CheckboxWrapper>
                    <Row>
                        <Column>
                            <InputFormField formField={streetAddress} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                        <Column>
                            <InputFormField formField={suiteAptNumber} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <InputFormField formField={city} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                        <Column>
                            <SelectFormField formField={state} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <ZipCodeFormField
                                formField={zip}
                                onChangeHandler={spouseDispatcher.updateSpouseAddressInfo}
                                errorMessage={zipStateErrorMessage}
                            />
                        </Column>
                        <Column />
                    </Row>
                    <Row>
                        <Column>
                            <InputFormField formField={county} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                        <Column></Column>
                    </Row>
                </span>
            ) : (
                <span className="align-field-mobile">
                    <Row>
                        <Column>
                            <InputFormField formField={firstName} onChangeHandler={spouseDispatcher.updateSpouseInfo} />
                        </Column>
                        <Column>
                            <InputFormField formField={lastName} onChangeHandler={spouseDispatcher.updateSpouseInfo} />
                        </Column>
                    </Row>
                    <CheckboxWrapper>
                        <CheckboxFormField
                            formField={spouseAddressSameAsPrimaryApplicant}
                            onChangeHandler={spouseDispatcher.updateSpouseAddressSameAsPrimaryApplicant}
                        />
                    </CheckboxWrapper>
                    <Row>
                        <Column>
                            <InputFormField formField={streetAddress} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                        <Column>
                            <InputFormField formField={suiteAptNumber} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <InputFormField formField={city} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                        <Column>
                            <SelectFormField formField={state} onChangeHandler={spouseDispatcher.updateSpouseAddressInfo} />
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <ZipCodeFormField
                                formField={zip}
                                onChangeHandler={spouseDispatcher.updateSpouseAddressInfo}
                                errorMessage={zipStateErrorMessage}
                            />
                        </Column>
                        <Column />
                    </Row>
                </span>
            )}
        </div>
    );
};

export default SpouseInfoForm;
