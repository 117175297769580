import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HorizontalRule } from '@makemydeal/ui-bricks/dist/cox';

import { submitIndividualApplicantPersonalInfo, submitJointApplicantPersonalInfo } from '../../actions/CreditAppMutators';
import { ButtonWrapper, DisclaimerText, PageContainer } from '../../components/shared.styled';
import SubmitButtonWithCheck, { ButtonState } from '../../components/SubmitLeadButton';
import WithPageUI from '../../components/WithPageUI';
import { useCreditAppDispatch, useCreditAppState } from '../../contexts/CreditAppContext';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { useSubmit } from '../../customHooks/UseSubmit.hook';
import { APPLICANT_CHOICE, PAGES } from '../../types/Constants';
import { Resources } from '../../types/ExternalResources';
import { KeyValuePair } from '../../types/KeyValuePair';
import { crossValidator, DOBValidators, RelationshipTypeValidator, SSNValidators } from '../../utils/crossValidation';
import { sendAccelerateCreditAppCoApplicantEmail, sendLead } from '../../utils/eventUtils';
import { getLambdaUrl } from '../../utils/externalUrls';
import { isEmptyObject } from '../../utils/helper';
import { RelationshipTypes } from '../../utils/selectOptions';
import { getUrlVars } from '../../utils/urlParameters';
import { validateVerifyEmail } from '../../utils/Validation';
import { Dispatcher } from '../Dispatcher';
import { Applicant } from './models/Applicant.model';
import PersonalInfoForm from './PersonalInfoForm';
import { InfoItemText, PersonalInfoTextContainer, PersonalInfoTitleText } from './PersonalInfoPage.styled';

const PersonalInfoPage: React.FC<{ location: Location }> = ({ location }) => {
    const creditAppState = useCreditAppState();
    const { isStandaloneExperience, isEmbeddedExperience, isUCAEmbeddedExperience } = useCreditAppExperience();
    const dispatch = useCreditAppDispatch();
    const history = useHistory();

    const [isJoint, setIsJoint] = useState(creditAppState.applicantChoice === APPLICANT_CHOICE.JOINT);
    const [submitLeadButtonState, setSubmitLeadButtonState] = React.useState(ButtonState.DEFAULT);

    const [primaryApplicant, setPrimaryApplicant] = React.useState(creditAppState.personalInfo?.primaryApplicant);
    const [jointApplicant, setJointApplicant] = React.useState(creditAppState.personalInfo?.jointApplicant);

    const primaryApplicantDispatcher = React.useMemo(() => new Dispatcher<Applicant>(setPrimaryApplicant), []);
    const jointApplicantDispatcher = React.useMemo(() => new Dispatcher<Applicant>(setJointApplicant), []);

    const externalResourcesState = useExternalResourceState();
    const validateCoAppRelationship = (externalResourcesState as Resources)?.toggles?.toggles?.validateCoAppRelationship;
    const updateSSNValidation = (externalResourcesState as Resources)?.toggles?.toggles?.updateSSNValidation;

    // has to be overwritten since the verifyEmail needs to know about the email value
    primaryApplicant.verifyEmail.validationFunction = (value: string) => validateVerifyEmail(value, primaryApplicant.email.value);
    jointApplicant.verifyEmail.validationFunction = (value: string) => validateVerifyEmail(value, jointApplicant.email.value);

    // ssn validation
    const validatePrimarySSN = crossValidator(
        SSNValidators,
        primaryApplicant.ssn.value,
        jointApplicant.ssn.value,
        updateSSNValidation?.toString()
    );
    const validateJointSSN = crossValidator(
        SSNValidators,
        jointApplicant.ssn.value,
        primaryApplicant.ssn.value,
        updateSSNValidation?.toString()
    );
    primaryApplicant.ssn.validationFunction = () => validatePrimarySSN.length === 0;
    primaryApplicant.ssn.errorMessage = validatePrimarySSN.length > 0 ? validatePrimarySSN[0] : '';
    jointApplicant.ssn.validationFunction = () => validateJointSSN.length === 0;
    jointApplicant.ssn.errorMessage = validateJointSSN.length > 0 ? validateJointSSN[0] : '';

    // dob validation
    const validatePrimaryDOB = crossValidator(DOBValidators, primaryApplicant.dob.value, jointApplicant.dob.value);
    const validateJointDOB = crossValidator(DOBValidators, jointApplicant.dob.value, primaryApplicant.dob.value);
    primaryApplicant.dob.validationFunction = () => validatePrimaryDOB.length === 0;
    primaryApplicant.dob.errorMessage = validatePrimaryDOB.length > 0 ? validatePrimaryDOB[0] : '';
    jointApplicant.dob.validationFunction = () => validateJointDOB.length === 0;
    jointApplicant.dob.errorMessage = validateJointDOB.length > 0 ? validateJointDOB[0] : '';

    // Co-applicant relationshipType Validation (in case primary is unmarried, coApp can not be spouse).
    if (validateCoAppRelationship) {
        const validateCoAppRelationshipType: string[] = crossValidator(
            RelationshipTypeValidator,
            jointApplicant?.relationship?.value,
            primaryApplicant?.maritalStatus?.value
        );
        jointApplicant.relationship.validationFunction = () => validateCoAppRelationshipType.length === 0;
        jointApplicant.relationship.errorMessage = validateCoAppRelationshipType.length > 0 ? validateCoAppRelationshipType[0] : '';
    }

    // This avoids validation error on the primaryApplicant.relationship field
    React.useEffect(() => {
        primaryApplicant.relationship.optional = true;
        setPrimaryApplicant(primaryApplicant);
    }, [primaryApplicant, primaryApplicant.relationship.optional]);

    React.useEffect(() => {
        const jointIsSpouse = jointApplicant.relationship.value === RelationshipTypes.Spouse;
        const jointMaritalStatusIsOptional = jointApplicant.maritalStatus.optional;
        if (jointIsSpouse && !jointMaritalStatusIsOptional) {
            setJointApplicant((jointApplicant) => {
                jointApplicant.maritalStatus.optional = true;
                return { ...jointApplicant };
            });
        }
        if (!jointIsSpouse && jointMaritalStatusIsOptional) {
            setJointApplicant((jointApplicant) => {
                jointApplicant.maritalStatus.optional = false;
                return { ...jointApplicant };
            });
        }
    }, [jointApplicant.maritalStatus.optional, jointApplicant.relationship.value]);

    React.useEffect(() => {
        setIsJoint(creditAppState.applicantChoice === APPLICANT_CHOICE.JOINT);
    }, [creditAppState.applicantChoice]);

    const onSuccess = () => {
        sendLead({
            firstName: primaryApplicant.firstName.getTrimmed() ?? '',
            middleName: primaryApplicant.middleName.getTrimmed() ?? '',
            lastName: primaryApplicant.lastName.getTrimmed() ?? '',
            phone: primaryApplicant.phone.getTrimmed() ?? '',
            email: primaryApplicant.email.getTrimmed() ?? ''
        });
        history.push(PAGES['/housing'].urlPath);
    };

    const SubmitIndividual = (): any => {
        const statePayload = {
            primaryApplicant: {
                state: primaryApplicant,
                setter: setPrimaryApplicant
            }
        };
        return useSubmit(
            dispatch,
            submitIndividualApplicantPersonalInfo,
            statePayload,
            undefined,
            location,
            isStandaloneExperience
        );
    };

    const SubmitJoint = (): KeyValuePair => {
        const stateModel = {
            primaryApplicant: {
                state: primaryApplicant,
                setter: setPrimaryApplicant
            },
            jointApplicant: {
                state: jointApplicant,
                setter: setJointApplicant
            }
        };
        return useSubmit(dispatch, submitJointApplicantPersonalInfo, stateModel, undefined, location, isStandaloneExperience);
    };

    const Submit = () => {
        let errors: KeyValuePair;
        if (isJoint) errors = SubmitJoint();
        else errors = SubmitIndividual();
        if (isEmptyObject(errors)) {
            const coApplicantEmail = creditAppState.personalInfo?.jointApplicant?.email.value;
            if (coApplicantEmail) {
                sendAccelerateCreditAppCoApplicantEmail(coApplicantEmail);
            }
            isEmbeddedExperience ? setSubmitLeadButtonState(ButtonState.SENDING) : history.push(PAGES['/housing'].urlPath);
        }
    };

    const urlParams = getUrlVars();
    const [sourcePartnerId, setSourcePartnerId] = React.useState('');
    const [sourcePartnerDealerId, setSourcePartnerDealerId] = React.useState('');
    if (!sourcePartnerId) {
        if (urlParams.sourcePartnerId) {
            setSourcePartnerId(urlParams.sourcePartnerId);
        }
    }

    if (!sourcePartnerDealerId) {
        if (urlParams.sourcePartnerDealerId) {
            setSourcePartnerDealerId(urlParams.sourcePartnerDealerId);
        }
    }
    const headers = {
        SourcePartnerId: sourcePartnerId,
        SourcePartnerDealerId: sourcePartnerDealerId
    };

    const prequalData = [
        {
            type: 'applicant',
            firstName: primaryApplicant.firstName.value,
            lastName: primaryApplicant.lastName.value,
            email: primaryApplicant.email.value,
            phoneNumber: primaryApplicant.phone.value,
            dob: primaryApplicant.dob.value,
            ssn: primaryApplicant.ssn.value
        }
    ];

    if (isJoint) {
        prequalData.push({
            type: 'coApplicant',
            firstName: jointApplicant.firstName.value,
            lastName: jointApplicant.lastName.value,
            email: jointApplicant.email.value,
            phoneNumber: jointApplicant.phone.value,
            dob: jointApplicant.dob.value,
            ssn: jointApplicant.ssn.value
        });
    }

    const checkPrequal = () =>
        axios
            .post(
                `${getLambdaUrl()}/prequal`,
                {
                    // Pass applicant info as the request body
                    consumers: prequalData
                },
                {
                    headers
                }
            )
            .then((resp) => {
                console.log(resp);
                if (resp.data.success) {
                    Submit();
                }
            })
            .catch((error) => {
                console.error(error);
            });

    return (
        <PageContainer>
            <PersonalInfoTextContainer>
                <PersonalInfoTitleText>
                    <FontAwesomeIcon icon={faInfoCircle} className="personal-info-font-icon" />
                    <InfoItemText>
                        <span className="info-item-text-one">
                            Please enter your first, middle, and last name as it appears on driver's license.
                        </span>
                        <span className="info-item-text-two">
                            Nicknames or misspellings may result in an incomplete application.
                        </span>
                    </InfoItemText>
                </PersonalInfoTitleText>
            </PersonalInfoTextContainer>

            <PersonalInfoForm
                title="Primary Applicant"
                className="primary-applicant"
                applicant={primaryApplicant as Applicant}
                applicantDispatcher={primaryApplicantDispatcher}
                isJoint={isJoint}
                isPrimary={true}
                isUCAEmbeddedExperience={isUCAEmbeddedExperience}
            />
            {isJoint && (
                <>
                    <HorizontalRule />
                    <PersonalInfoForm
                        title="Co-Applicant"
                        className="co-applicant"
                        applicant={jointApplicant as Applicant}
                        applicantDispatcher={jointApplicantDispatcher}
                        isJoint={isJoint}
                        isPrimary={false}
                        isUCAEmbeddedExperience={isUCAEmbeddedExperience}
                    />
                </>
            )}
            <DisclaimerText>
                By completing this application you authorize us to obtain and verify information about you including access to your
                credit reports.
            </DisclaimerText>

            {isUCAEmbeddedExperience ? (
                <SubmitButtonWithCheck onClick={checkPrequal} state={submitLeadButtonState} onSuccess={onSuccess} label="Next" />
            ) : isEmbeddedExperience ? (
                <SubmitButtonWithCheck onClick={Submit} state={submitLeadButtonState} onSuccess={onSuccess} label="Next" />
            ) : (
                <ButtonWrapper onClick={Submit}>Next</ButtonWrapper>
            )}
        </PageContainer>
    );
};
export default WithPageUI(PersonalInfoPage);
