import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@makemydeal/ui-bricks/dist/cox';

import { submitReview } from '../../actions/CreditAppMutators';
import LabelWithModal from '../../components/LabelWithModal/LabelWithModal';
import { LinkLabelComponentWrapper } from '../../components/LabelWithModal/LabelWithModal.styled';
import {
    ButtonWrapper,
    CheckboxWrapperForCheckbox,
    ErrorMessageContainer,
    ErrorMsgWrapperForPrivacyNoticeIndicator,
    PageContainer
} from '../../components/shared.styled';
import WithPageUI from '../../components/WithPageUI';
import { useCreditAppDispatch, useCreditAppState } from '../../contexts/CreditAppContext';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { useSubmit } from '../../customHooks/UseSubmit.hook';
import { CheckboxFormField } from '../../formFields';
import { jointAppFromNonCPState, jointAppNonSpousalFromCPState } from '../../integration/FD-v2.0/Adapter';
import { APPLICANT_CHOICE, PAGES, VehicleSectionTitles } from '../../types/Constants';
import { GetType, Payment, Resources, TradeIn, Vehicle } from '../../types/ExternalResources';
import { buildVehicleSectionData } from '../../utils/helper';
import { Dispatcher } from '../Dispatcher';
import { InfoCardData } from '../Interfaces';
import CreditDecisionDisclaimer from './CreditDecisionDisclaimer';
import { Review } from './models/Review.model';
import { PrivacyContent } from './PrivacyContent';
import { FooterText, JointInfoWrapper } from './ReviewPage.styled';
import ReviewPageForm from './ReviewPageForm';
import { TermsAndConditionContent } from './TermsAndConditionContent';
import VehicleSummaryForm from './VehicleSummaryForm';

export interface ReviewFormData {
    personalInfo: InfoCardData;
    housingInfo: InfoCardData;
    employmentInfo: InfoCardData;
}

export interface VehicleFormData {
    vehicleInfo: GetType<InfoCardData>;
    tradeInInfo: GetType<InfoCardData>;
    financeInfo: GetType<InfoCardData>;
}

const ReviewPage: React.FC<{ location: Location }> = ({ location }) => {
    const creditAppState = useCreditAppState();

    const dispatch = useCreditAppDispatch();

    const externalResourcesState = useExternalResourceState();

    const { isStandaloneExperience, isUCAEmbeddedExperience } = useCreditAppExperience();

    const clientPrivacyNote = (externalResourcesState as Resources)?.dealer?.privacyNoticeUrl;

    const isCreditDecisionEnabled = (externalResourcesState as Resources)?.dealer?.isAccelerateCreditDecisionEnabled;

    const showVehicleInfoInReview = (externalResourcesState as Resources)?.toggles?.toggles?.showVehicleInfoInReview;
    const showTradeInInfoInReview = (externalResourcesState as Resources)?.toggles?.toggles?.showTradeInInfoInReview;
    const showFinanceInfoInReview = (externalResourcesState as Resources)?.toggles?.toggles?.showFinanceInfoInReview;

    const dealerName = (externalResourcesState as Resources)?.dealer?.name || 'The dealer';

    const [statusTerms, setStatusTerms] = React.useState(false);

    const statusTermsHandleClick = () => {
        setStatusTerms(!statusTerms);
    };
    const [statusPrivacy, setStatusPrivacy] = React.useState(false);

    const statusPrivacyHandleClick = () => {
        setStatusPrivacy(!statusPrivacy);
    };

    const isJoint = creditAppState.applicantChoice === APPLICANT_CHOICE.JOINT;

    const isRegulationBCandidate = jointAppNonSpousalFromCPState(creditAppState) || jointAppFromNonCPState(creditAppState);

    const toggleErrorMsg =
        !creditAppState.reviewInfo.privacyNoticeIndicator.isChecked && creditAppState.reviewInfo.privacyNoticeIndicator.hasError;

    const displayCreditDecisionDisclaimer = !isStandaloneExperience && isCreditDecisionEnabled;

    const history = useHistory();

    const buildFormData = (personalInfoData: InfoCardData, housingInfoData: InfoCardData, employmentInfoData: InfoCardData) => {
        const housingInfo = housingInfoData;
        const personalInfo = personalInfoData;
        const employmentInfo = employmentInfoData;
        if (housingInfo.spouseName) {
            personalInfo.spouseName = housingInfo.spouseName;
            delete housingInfo.spouseName;
        }
        return { personalInfo, housingInfo, employmentInfo };
    };

    const buildVehicleFormData = (
        vehicleInfoData: GetType<Vehicle>,
        tradeInInfoData: GetType<TradeIn>,
        financeInfoData: GetType<Payment>
    ) => {
        const vehicleInfo = buildVehicleSectionData(VehicleSectionTitles.VEHICLE, vehicleInfoData);
        const tradeInInfo = buildVehicleSectionData(VehicleSectionTitles.TRADE_IN, tradeInInfoData);
        const financeInfo = buildVehicleSectionData(VehicleSectionTitles.FINANCE, financeInfoData);
        return { vehicleInfo, tradeInInfo, financeInfo };
    };

    const primaryPersonalInfo = creditAppState.personalInfo.primaryApplicant.reviewAdapter(false);
    const primaryHousingInfo = creditAppState.housingInfo.primaryApplicant.reviewAdapter();
    const primaryEmploymentInfo = creditAppState.employmentInfo.primaryApplicant.reviewAdapter();

    const primaryApplicantFormData: ReviewFormData = buildFormData(primaryPersonalInfo, primaryHousingInfo, primaryEmploymentInfo);

    const jointPersonalInfo = creditAppState.personalInfo.jointApplicant.reviewAdapter(isJoint);
    const jointHousingInfo = creditAppState.housingInfo.jointApplicant.reviewAdapter();
    const jointEmploymentInfo = creditAppState.employmentInfo.jointApplicant.reviewAdapter();
    const jointApplicantFormData: ReviewFormData = buildFormData(jointPersonalInfo, jointHousingInfo, jointEmploymentInfo);

    const vehicleInfo = (externalResourcesState as Resources)?.offer?.vehicle;
    const tradeInInfo = (externalResourcesState as Resources)?.offer?.tradeIn;
    const financeInfo = (externalResourcesState as Resources)?.offer?.payment;
    const vehicleInfoFormData: VehicleFormData = buildVehicleFormData(vehicleInfo, tradeInInfo, financeInfo);

    const [reviewInfo, setReviewInfo] = useState(creditAppState.reviewInfo);

    const dispatcher = React.useMemo(() => new Dispatcher<Review>(setReviewInfo), []);
    // This avoids validation error on the reviewInfo.regulationBIndicator field
    React.useEffect(() => {
        if (isRegulationBCandidate) {
            creditAppState.reviewInfo.regulationBIndicator.optional = false;
        } else {
            creditAppState.reviewInfo.regulationBIndicator.optional = true;
            creditAppState.reviewInfo.regulationBIndicator.validationFunction = () => true;
        }
        setReviewInfo(reviewInfo);
    }, [
        creditAppState.reviewInfo.regulationBIndicator,
        creditAppState.reviewInfo.regulationBIndicator.optional,
        isJoint,
        isRegulationBCandidate,
        reviewInfo
    ]);

    const handleRoute = () => {
        if (isStandaloneExperience) {
            history.push(PAGES['/confirmationStandAlone'].urlPath);
        } else {
            history.push(PAGES['/confirmation'].urlPath);
        }
    };

    const Submit = () => {
        const statePayload = {
            review: {
                state: reviewInfo,
                setter: setReviewInfo
            }
        };
        return useSubmit(dispatch, submitReview, statePayload, handleRoute, location, isStandaloneExperience);
    };
    return (
        <PageContainer>
            <ReviewPageForm
                title="Primary Applicant"
                className="primary-applicant"
                formData={primaryApplicantFormData}
                experience={isStandaloneExperience}
            />
            {isJoint && (
                <JointInfoWrapper>
                    <ReviewPageForm
                        title="Co-Applicant"
                        className="co-applicant"
                        formData={jointApplicantFormData}
                        experience={isStandaloneExperience}
                    />
                </JointInfoWrapper>
            )}
            {isUCAEmbeddedExperience &&
                ((showVehicleInfoInReview && vehicleInfoFormData.vehicleInfo) ||
                    (showTradeInInfoInReview && vehicleInfoFormData.tradeInInfo) ||
                    (showFinanceInfoInReview && vehicleInfoFormData.financeInfo)) && (
                    <JointInfoWrapper>
                        <VehicleSummaryForm
                            title="Vehicle Summary"
                            className="vehicle-summary"
                            formData={vehicleInfoFormData}
                            showVehicleInfoInReview={showVehicleInfoInReview}
                            showTradeInInfoInReview={showTradeInInfoInReview}
                            showFinanceInfoInReview={showFinanceInfoInReview}
                        />
                    </JointInfoWrapper>
                )}
            <CheckboxWrapperForCheckbox color={toggleErrorMsg ? '#C3032F' : undefined}>
                <CheckboxFormField
                    formField={creditAppState.reviewInfo.privacyNoticeIndicator}
                    onChangeHandler={dispatcher.updateCheckboxValue}
                />
                <span>I agree to the </span>
                <LabelWithModal
                    isOpen={statusTerms}
                    linkLabel="Terms and Conditions"
                    modalTitleLabel="Terms and Conditions"
                    closeButtonLabel="close"
                    content={TermsAndConditionContent}
                    modalHeight="80%"
                    handleClick={statusTermsHandleClick}
                />
                <span> and </span>
                {clientPrivacyNote ? (
                    <LinkLabelComponentWrapper>
                        <Link href={clientPrivacyNote} target="_blank" className="primary-link">
                            Privacy Notice
                        </Link>
                    </LinkLabelComponentWrapper>
                ) : (
                    <LabelWithModal
                        isOpen={statusPrivacy}
                        linkLabel="Privacy Notice"
                        modalTitleLabel="Privacy Notice"
                        closeButtonLabel="close"
                        content={PrivacyContent}
                        handleClick={statusPrivacyHandleClick}
                    />
                )}
            </CheckboxWrapperForCheckbox>
            {toggleErrorMsg && (
                <ErrorMessageContainer className="privacy-notice-error">
                    <FontAwesomeIcon icon={faInfoCircle} color={'#C3032F'} size="lg" />
                    <ErrorMsgWrapperForPrivacyNoticeIndicator className="input-error-message-wrapper">
                        Please accept the Terms and Conditions and Privacy Notice
                    </ErrorMsgWrapperForPrivacyNoticeIndicator>
                </ErrorMessageContainer>
            )}

            {isRegulationBCandidate && (
                <CheckboxWrapperForCheckbox>
                    <CheckboxFormField
                        formField={creditAppState.reviewInfo.regulationBIndicator}
                        onChangeHandler={dispatcher.updateCheckboxValue}
                    />
                    <span>
                        By clicking here, you both certify in accordance with Regulation B that you both intend to apply for joint
                        credit.
                    </span>
                </CheckboxWrapperForCheckbox>
            )}

            <ButtonWrapper data-testid="next" onClick={Submit} disabled={false}>
                Submit
            </ButtonWrapper>
            {displayCreditDecisionDisclaimer ? (
                <CreditDecisionDisclaimer dealerName={dealerName} />
            ) : (
                <FooterText>
                    By clicking on "Submit", I acknowledge and declare that I have read and agree with the Terms and Conditions. I
                    certify that I have provided complete and true information in this application.
                </FooterText>
            )}
        </PageContainer>
    );
};
export default WithPageUI(ReviewPage);
